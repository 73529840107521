import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AccountContext } from "../auth/account";
import PopupSetupIntent from "../dashboard/payments/PopupSetupIntent";

import classes from "./ExamCheckout.module.scss";

import ENV from "../../env";
import Spinner from "../Spinner";

const ExamCheckout = () => {
  const { getSession } = useContext(AccountContext);

  const [isPaymentMethodsLoading, setIsPaymentMethodsLoading] = useState(true); // Loading payment methods status
  const [userPaymentMethods, setUserPaymentMethods] = useState([]); // User payment methods
  const [selectedPayment, setSelectedPayment] = useState(""); // User selected payment method

  const [paymentSubmited, setPaymentSubmited] = useState(false); // Payment successfully processed

  const [isSubmitBtnLoader, setIsSubmitBtnLoader] = useState(false); // Payment processing status

  const [isSetupIntentPopupOpen, setIsSetupIntentPopupOpen] = useState(false);

  const [isError, setIsError] = useState(false); // If eny error
  const [errorMsg, setErrorMsg] = useState([]); // Error message

  // Promo
  const [promoCode, setPromoCode] = useState(""); // Promo code
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false); // Promo code validation status
  const [showInvalidPromoCodeMsg, setShowInvalidPromoCodeMsg] = useState(false); // Show invalid promo code message

  let totalAmount = 20.0;
  let discountAmount = 5.0;

  useEffect(() => {
    if (errorMsg.length) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [errorMsg]);

  // Fetch user payment methods
  useEffect(() => {
    getSession()
      .then((session) => {
        axios
          .get(ENV.BASE_URL + "/payments/payment-methods", {
            headers: { Authorization: `Bearer ${session.idToken.jwtToken}` },
          })
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              setUserPaymentMethods(response.data.data);
              setIsPaymentMethodsLoading(false);
            } else {
              console.log("[ERROR]: Error happens while fetching user info.");
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getSession]);

  const onPaymentSubmit = () => {
    console.log("Pay and Post");
    // Validate payment method
    if (!selectedPayment) {
      setErrorMsg(["Please select a payment method"]);
      return;
    } else {
      setErrorMsg([]);
    }

    // Make API call and to payment
    getSession()
      .then((session) => {
        setIsSubmitBtnLoader(true);
        axios
          .post(
            ENV.BASE_URL + "/payments/create-payment-intent",
            {
              "user-pm": selectedPayment,
              "payment-amount": isPromoCodeValid
                ? parseInt((totalAmount - discountAmount) * 100)
                : parseInt(totalAmount * 100),
              "transaction-type": "exam",
              "referal-code": isPromoCodeValid ? promoCode : "",
              "original-amount": totalAmount,
              "discount-amount": isPromoCodeValid
                ? (totalAmount - discountAmount)
                : 0,
            },
            {
              headers: {
                Authorization: `Bearer ${session.idToken.jwtToken}`,
              },
            }
          )
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              setPaymentSubmited(true);
            } else {
              console.log("[ERROR]: Error happens while fetching user info.");
              setErrorMsg(["Error happens while processing payment"]);
              setPaymentSubmited(false);
            }
          })
          .catch((err) => {
            console.log("[ERROR]: " + err);
            setErrorMsg([err.response.data.error]);
            setPaymentSubmited(false);
            setIsSubmitBtnLoader(false);
          }
        );
      })
      .catch((err) => {
        console.log("[ERROR]: " + err);
        setErrorMsg(["Error happens while processing payment"]);
        setPaymentSubmited(false);
      });
  };

  const validatePromoCode = (code) => {
    if (code.toLowerCase() === "yurikc") {
      setIsPromoCodeValid(true);
      setShowInvalidPromoCodeMsg(false);
    } else {
      setIsPromoCodeValid(false);
      setShowInvalidPromoCodeMsg(true);
    }
  };

  const handlePromoCodeChange = (e) => {
    const code = e.target.value;
    setPromoCode(code);
  
    // Trigger validation only when the expected length is reached
    if (code.length === 6) {
      validatePromoCode(code);
    } else {
      setIsPromoCodeValid(false);
      setShowInvalidPromoCodeMsg(false);
    }
  };

  return (
    <>
      {paymentSubmited && (
        <div className={classes.container}>
          <p className={classes.title}>Thank you for your purchase!</p>
          <p className={classes.success}>
            Enjoy your benefits and good luck on your exam!
          </p>
          <div className={classes.submitButtonBlock}>
            <Link to={`/exam`} className={classes.button}>
              Back to Exam
            </Link>
          </div>
        </div>
      )}

      {!paymentSubmited && (
        <div className={classes.container}>
          <h1 className={classes.title}>Review & Checkout</h1>

          <div className={classes.mainSection}>
            <div className={classes.main}>
              <h3 className={classes.main__orderList__header}>Order details</h3>
              <div className={classes.main__orderList}>
                <div className={classes.box__row}>
                  <p className={classes.productTitle}>
                    **Practice Exam** - 3 months unlimited access
                  </p>
                </div>
                <div className={classes.box__row}>
                  <p></p>
                  <div className={classes.box__row__subtotal}>
                    <span>Subtotal:</span>{" "}
                    <span className={classes.price}>
                      <span>$</span>
                      {totalAmount.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.summary}>
              <h3 className={classes.main__orderList__header}>Order summary</h3>
              <div className={classes.order_info}>
                <div className={classes.order_info_row}>
                  <p>Subtotal:</p>
                  <p>
                    <span>$</span>
                    {totalAmount.toFixed(2)}
                  </p>
                </div>
                {!isPromoCodeValid && (
                  <div className={classes.order_info_row}>
                    <p>Promo/Refferal code:</p>
                  </div>
                )}
                {!isPromoCodeValid && (
                  <div
                    className={classes.order_info_row}
                    style={{
                      alignItems: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "0.5rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      style={{ width: "150px" }}
                      type="text"
                      value={promoCode}
                      maxLength={6}
                      placeholder="Promo code"
                      onChange={handlePromoCodeChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          validatePromoCode(promoCode);
                        }
                      }}
                    />
                    <button
                      className={classes.apply}
                      onClick={() => {
                        validatePromoCode(promoCode);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                )}
                {showInvalidPromoCodeMsg && (
                  <div
                    className={classes.order_info_row}
                    style={{ color: "red", marginTop: "-0.7rem" }}
                  >
                    Invalid promo code
                  </div>
                )}
                {isPromoCodeValid && (
                  <div className={classes.order_info_row}>
                    <p>Your promo code:</p>
                    <p>YURIKC</p>
                  </div>
                )}

                {isPromoCodeValid && (
                  <div className={classes.order_info_row}>
                    <p>Discount:</p>
                    <p>
                      <span>$</span>-{discountAmount.toFixed(2)}
                    </p>
                  </div>
                )}
                <hr className={classes.divider} />
                <div className={classes.order_info_row}>
                  <p>Order total:</p>
                  <p>
                    <span>$</span>
                    {isPromoCodeValid
                      ? (totalAmount - discountAmount).toFixed(2)
                      : totalAmount.toFixed(2)}
                  </p>
                </div>
              </div>

              <p className={classes.title}>Payments</p>
              {/* Show loading spinner while fetching data */}
              {isPaymentMethodsLoading && (
                <dev
                  style={{
                    maxHeight: "150px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </dev>
              )}

              {!isPaymentMethodsLoading && (
                <div className={classes.payments}>
                  {userPaymentMethods.map((card, i) => (
                    <div
                      className={classes.order_info_row}
                      key={i}
                      onClick={() => setSelectedPayment(card.id)}
                    >
                      <input
                        type="radio"
                        checked={selectedPayment === card.id}
                        value={card.id}
                        name="payment"
                        readOnly={true}
                      />{" "}
                      <p>
                        {" "}
                        **** {card.last4}{" "}
                        <span style={{ marginLeft: "2rem" }}>
                          {card.exp_month}/{card.exp_year}
                        </span>
                      </p>
                    </div>
                  ))}

                  {isSetupIntentPopupOpen && (
                    <PopupSetupIntent
                      fromURL="exam-checkout"
                      handleClose={() => {
                        setIsSetupIntentPopupOpen(false);
                      }}
                    />
                  )}
                </div>
              )}

              <div className={classes.mainSection}>
                <button
                  className={`${classes.linkStyle}`}
                  onClick={() => {
                    setIsSetupIntentPopupOpen(true);
                  }}
                >
                  + Add payment method
                </button>
              </div>
            </div>
          </div>

          {/* Error block */}
          <div
            className={classes.errorBlock}
            style={{ display: isError ? "block" : "none" }}
          >
            <ul>
              {errorMsg.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>

          {/* Submit */}
          {!isSubmitBtnLoader && (
            <div className={classes.submitButtonBlock}>
              <button className={classes.button} onClick={onPaymentSubmit}>
                Submit
              </button>
            </div>
          )}

          {isSubmitBtnLoader && (
            <p
              style={{
                justifyContent: "center",
                display: "flex",
                margin: "1rem",
              }}
            >
              Processing . . .
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default ExamCheckout;
